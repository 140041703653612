<template>
    <div class="container">
        <div class="refund-title">选择退货商品</div>
        <div class="refund-goods-list" v-for="item in dataList" :key="item.id">
            <div class="refund-select-icon" @click="selectGoodClick(item)">
                <img :src="item.select? require('../../images/gouxuan2.png'): require('../../images/gouxuan1.png')"/>
            </div>
            <div class="goods-info">
                <img class="goods-img" :src="item.photoUrl" />
                <div class="goods-info-r">
                <text class="goods-name">{{item.goodsName}}{{item.select}}</text>
                <text class="goods-price">¥{{item.nowGoodsPrice}}</text>
                </div>
            </div>
            <view class="goods-num">×{{item.nowGoodsNumber}}</view>
        </div>
        <!-- <div class="refund-goods-list">
            <div class="refund-select-icon" @click="selectGoodClick">
                <img src='../../images/gouxuan1.png'/>
            </div>
            <div class="goods-info">
                <img class="goods-img" src="../../images/goods02.jpg" />
                <div class="goods-info-r">
                <text class="goods-name">商品名称</text>
                <text class="goods-price">¥10</text>
                </div>
            </div>
            <view class="goods-num">×23</view>
        </div> -->
    </div>
    <div class="fixed-bom">确定</div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
export default {
  data () {
    return {
      dataList: [],
      orderId: ''
    }
  },
  methods: {
    async getDataList () {
      const url = 'v1/orderInfo/client/details.json'
      const param = {
        userId: localStorage.userId,
        addUserId: localStorage.userId,
        orderId: this.orderId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      const dataList = res.data.entity.goodsList
      dataList.forEach(item => {
        item.select = false
      })
      this.dataList = dataList
    },
    // 点击选择
    selectGoodClick (model) {
      model.select = !model.select
      this.dataList.forEach(item => {
        if (item.id === model.id) {
        }
      })
    }
  },
  created () {
    this.orderId = this.$route.params.orderId
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
// @import '../style/viriables.scss';
.container{
    position: absolute;
    left:0; right:0; bottom:0; top:0;
    padding-bottom: .56rem;
    overflow: auto;
    background:#fff;
    .refund-title{
        height: .2rem;
        padding: .1rem .15rem;
        border-bottom: 1px solid #f3f3f3;
        line-height: .2rem;
    }
    .refund-goods-list {
        display: flex;
        padding: .1rem .15rem;
        border-bottom: 1px solid #f3f3f3;
        align-items: center;
        .refund-select-icon img{width:.17rem; height: .17rem; margin-right: .15rem}
        .goods-info{
            flex: 1;
            display: flex;
            .goods-img {width: .6rem; height: .6rem; margin-right: .1rem; border-radius: .05rem;}
            .goods-info-r{display: flex; flex-direction: column; justify-content: space-between;}
            .goods-name{font-size: .14rem;}
            .goods-price{padding-bottom: .02rem; font-size: .13rem; color:#52da5f}
        }
        .goods-num{font-size: .13rem; color:#52da5f}
    }
}
</style>
